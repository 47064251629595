SnapeApp.createModule("SnapeApp.Helpers.Highlight", function () {
    "use strict";

    var highlight = {
        highlightText: function (textBoxId) {
            var selector = '#' + textBoxId;
            $(selector).select();
        }
    };

    return highlight;
    // moDule DepenDs on JQuery
}, [jQuery, moment]);

SnapeApp.createModule("SnapeApp.Helpers.TimeZone", function () {
    "use strict";
    var timezone = function () {
        var time = Intl.DateTimeFormat().resolvedOptions().timeZone;
        return time;
    };

    var datetimeformat = function (format) {
        var mapObj = {
            yyyy: "YYYY",
            dd: "DD"
        };
        format = format.replace(/yyyy|dd/gi, function (matched) {
            return mapObj[matched];
        });

        return format;
    };

    return {
        timezone: timezone,
        format: datetimeformat
    };

}, [jQuery]);

/* 
  Common function for mouse event listener with mobile support using JQuery.
  The argument is selector and callback.
  example of use in GatewaySettings.js that call MouseEventWithMobileSupport module
*/
SnapeApp.createModule("SnapeApp.Helpers.MouseEventWithMobileSupport", function () {
    "use strict";

    var mouseEvent = function (selector, callback) {
        $(document).on({
            mouseenter: callback,
            click: callback,
            touchstart: callback
        }, selector);
    }

    return mouseEvent;

}, [jQuery]);

SnapeApp.createModule("SnapeApp.Helpers.DisplayDateTimeInUTC", function () {
    "use strict";
    var datetimeformat = function (data, dFormat, timezone) {
        if (!data) {
            return '';
        }
        let timezoneUTC = moment(data, dFormat).tz(timezone).format('Z');
        return moment.utc(data, dFormat).tz(timezone).format(dFormat) + " UTC" + timezoneUTC;
    };

    return {
        datetimeformat: datetimeformat
    };

}, [jQuery]);

SnapeApp.createModule("SnapeApp.Helpers.EpochTime.DisplayDateTimeInUTC", function () {
    "use strict";
    var datetimeformat = function (epochTime, dFormat, timezone) {
        let timezoneUTC = moment(epochTime).tz(timezone).format('Z');
        return moment.utc(epochTime).tz(timezone).format(dFormat) + " UTC" + timezoneUTC;
    };

    return {
        datetimeformat: datetimeformat
    };

}, [jQuery]);

SnapeApp.createModule("SnapeApp.Helpers.Dates", function () {
    "use strict";

    var localizeDate = function localizeDate(epochTime, format, isDotNetTicks) {

        if (isDotNetTicks) {
            /*Timestamp is pushed to server in C# ticks(UTC). C# time ticks from 0000-01-01. The subtraction of
            621355968000000000 takes away the excess ticks from 0000-01-01 to 1970-01-01. There are 10000 .net
            ticks per millisecond. The JavaScript Date type's origin is the Unix epoch: midnight on 1 January 1970.
            The .NET DateTime type's origin is midnight on 1 January 0001.*/
            epochTime = (epochTime - 621355968000000000) / 10000;
        }
        var currentMoment = moment.unix(epochTime).locale(cultureInfo);//seconds
        var curDate = currentMoment.format(format);
        return curDate;
    };

    var localizeDates = function localizeDates() {
        $('[data-date]').each(function () {

            var date = new Date($(this).attr('data-date'));
            var utcDate = moment(date).utc();

            $(this).html(localizeDate(utcDate.local(), 'L, LTS', true));
        });
    };

    var dates = {
        localizeDate: localizeDate,
        localizeDates: localizeDates
    };

    return dates;
}, [jQuery, moment, cultureInfo]);

SnapeApp.createModule("SnapeApp.Helpers.QueryString", function () {

    // returns a single parameter from the current query string
    // adapted from SO: http://stackoverflow.com/a/901144
    var getParameter = function (name) {
        name = name.replace(/[\[]/, "\\[").replace(/[\]]/, "\\]");
        var regex = new RegExp("[\\?&]" + name + "=([^&#]*)"),
            results = regex.exec(location.search);
        return results === null ? "" : decodeURIComponent(results[1].replace(/\+/g, " "));
    };

    return {
        getParameter: getParameter
    };
});

SnapeApp.createModule("SnapeApp.Helpers.Export", function () {

    // export project/node/sensor data
    var ExportData = function (e) {

        var data = $("#SelectWeeks").data('daterangepicker');

        var exportAllTime = data.allTime;
        var fromDate = exportAllTime ? "" : moment(data.startDate._d).format("YYYY/MM/DD");
        var toDate = exportAllTime ? "" : moment(data.endDate._d).format("YYYY/MM/DD");

        data.exportAllTime(false);
        // $('#SelectWeeks').prop('disabled', false);

        if (fromDate === toDate && !exportAllTime) {
            $("#fromdatevalidationmsg").removeClass("hidden");
            $("#daterangeerror").addClass("hidden");
        } else {
            $("#fromdatevalidationmsg").addClass("hidden");
            let fromDateField;
            let toDateField;

            let from = moment(fromDate).format("YYYY/MM/DD");
            let to = moment(toDate).format("YYYY/MM/DD");
            fromDateField = new Date(from);
            toDateField = new Date(to);

            if (fromDateField > toDateField) {
                $("#daterangeerror").removeClass("hidden");
            } else {
                $("#daterangeerror").addClass("hidden");
                $("#exportDataIndicator").removeClass("hidden");
                let element = $(e);
                let IdElement = element.attr("data-projectId");
                let projectIdArray = IdElement.split("_");
                let projectId = projectIdArray[1];
                var timer;
                let timeStamp = Date.now().toString();
                let deviceId;

                if (IdElement.includes("project")) {

                    let projectName = element.attr("data-projectName");
                    let href = "/Export/DownloadZip?projectId=" + projectId + "&projectName=" + projectName + "&key=" + timeStamp + "&from=" + fromDate + "&to=" + toDate;
                    document.getElementById("fileDownloaderFrame").src = href;
                    let cookiStringTimeout = 'timeOutForRequest=' + timeStamp;
                    let cookieString = "dlzip=" + timeStamp;
                    let cookieStringFailMessage = "dlzip=fail_" + timeStamp;

                    timer = window.setInterval(function () {
                        if (document.cookie.indexOf(cookieString) !== -1) {
                            $("#exportDataIndicator").addClass("hidden");
                            SnapeApp.Helpers.UIOperations.showMessage(Resources.ProjectListJS.ExportProjectDataSuccess, true, "toast-bottom-left");
                            document.getElementById("fileDownloaderFrame").src = "about:blank";
                            window.clearInterval(timer);
                            $("#weekPickerModal").modal('hide');
                        }
                        if (document.cookie.indexOf(cookieStringFailMessage) !== -1) {
                            $("#exportDataIndicator").addClass("hidden");
                            // localize export alert.
                            SnapeApp.Helpers.UIOperations.showMessage(Resources.ProjectListJS.ErrorExportingNoData, false, "toast-bottom-left");
                            document.getElementById("fileDownloaderFrame").src = "about:blank";
                            window.clearInterval(timer);
                            $("#weekPickerModal").modal('hide');
                        }
                        if (document.cookie.indexOf(cookiStringTimeout) !== -1) {
                            $("#exportDataIndicator").addClass("hidden");
                            document.getElementById("fileDownloaderFrame").src = "about:blank";
                            window.location = '/Account/Login?isSessionTimeOut=true';
                            window.clearInterval(timer);
                            $("#weekPickerModal").modal('hide');
                        }

                    }, 100);

                } else if (IdElement.includes("node")) {

                    deviceId = element.attr("data-deviceId");
                    let href = "/Export/ConcatNodeCSV?deviceId=" + deviceId + "&projectId=" + projectId + "&key=" + timeStamp + "&from=" + fromDate + "&to=" + toDate;
                    let downloadGrabber = document.getElementById("fileDownloaderFrame");
                    downloadGrabber.src = href;
                    let cookiStringTimeout = 'timeOutForRequest=' + timeStamp;
                    let cookieString = 'nodeCsv=' + timeStamp;
                    let cookieStringFailMessage = 'nodeCsv=fail_' + timeStamp;

                    timer = window.setInterval(function () {
                        if (document.cookie.indexOf(cookieString) !== -1) {
                            $("#exportDataIndicator").addClass("hidden");
                            downloadGrabber.src = "about:blank";
                            SnapeApp.Helpers.UIOperations.showMessage(Resources.ProjectListJS.ExportProjectDataSuccess, true, "toast-bottom-left");
                            window.clearInterval(timer);
                            $("#weekPickerModal").modal('hide');
                        }
                        if (document.cookie.indexOf(cookieStringFailMessage) !== -1) {
                            $("#exportDataIndicator").addClass("hidden");
                            downloadGrabber.src = "about:blank";
                            SnapeApp.Helpers.UIOperations.showMessage(Resources.ProjectListJS.ErrorExportingNoData, false, "toast-bottom-left");
                            window.clearInterval(timer);
                            $("#weekPickerModal").modal('hide');
                        }
                        if (document.cookie.indexOf(cookiStringTimeout) !== -1) {
                            $("#exportDataIndicator").addClass("hidden");
                            downloadGrabber.src = "about:blank";
                            window.location = '/Account/Login?isSessionTimeOut=true';
                            window.clearInterval(timer);
                            $("#weekPickerModal").modal('hide');
                        }
                    }, 100);

                } else {
                    deviceId = element.attr("data-deviceId");
                    let sensorIndex = element.attr("data-sensorIndex");
                    let href = "/Export/ConcatSensorCSV?deviceId=" + deviceId + "&sensorIndex=" + sensorIndex + "&projectId=" + projectId + "&key=" + timeStamp + "&from=" + fromDate + "&to=" + toDate;
                    document.getElementById("fileDownloaderFrame").src = href;

                    let cookiStringTimeout = 'timeOutForRequest=' + timeStamp;
                    let cookieString = 'senCsv=' + timeStamp;
                    let errorCookieString = 'senCsv=fail_' + timeStamp;

                    timer = window.setInterval(function () {
                        if (document.cookie.indexOf(cookieString) !== -1) {
                            $("#exportDataIndicator").addClass("hidden");
                            SnapeApp.Helpers.UIOperations.showMessage(Resources.ProjectListJS.ExportProjectDataSuccess, true, "toast-bottom-left");
                            window.clearInterval(timer);
                            $("#weekPickerModal").modal('hide');
                        } else if (document.cookie.indexOf(errorCookieString) !== -1) {
                            $("#exportDataIndicator").addClass("hidden");
                            SnapeApp.Helpers.UIOperations.showMessage(Resources.ProjectListJS.ErrorExportingNoData, false, "toast-bottom-left");
                            window.clearInterval(timer);
                            $("#weekPickerModal").modal('hide');
                        }
                        if (document.cookie.indexOf(cookiStringTimeout) !== -1) {
                            $("#exportDataIndicator").addClass("hidden");
                            window.location = '/Account/Login?isSessionTimeOut=true';
                            window.clearInterval(timer);
                            $("#weekPickerModal").modal('hide');
                        }
                    }, 100);
                }
            }
        }
    };

    return {
        ExportData: ExportData
    };
});

// helper module called to get telemetry data like device state, voltage, signal, etc. Used in conjunction with ProjectSummary.js on the Home.cshtml view.
// the titles are localized for viewing.
// the resource file for this helper is SnapeHelperScriptsJS.culture.resx
SnapeApp.createModule("SnapeApp.Helpers.Telemetry", function () {
    "use strict";

    const renderIconButton = (htmlString) => {
        if (!htmlString()) {
            return ""
        }

        return `
            <div class="icon-button">
                ${htmlString()}
            </div>
        `
    }
    var htmlEncode = function (data) {
        // "trick" to HTML encode data from JS--essentially dip it in a <div> and pull it out again
        return data ? $('<div/>').text(data).html() : null;
    };

    var getNodeErrorIcon = (errorState) => renderIconButton(function () {
        if (errorState === 1) {
            return '<i class="material-icons icon-with-tooltip" title="' + Resources.SnapeHelperScriptsJS.IncompleteSetupError + '">error</i>';
        }
        return '';
    });

    var getfirmwareMismatchErrorIcon = (fwState) => renderIconButton(function () {
        if (fwState === 2) {
            return '<i class="material-icons icon-with-tooltip" title="' + Resources.SnapeHelperScriptsJS.FirmwareMismatchError + '">sync_problem</i>';
        } else if (fwState === 3) {
            return '<i class="material-icons icon-with-tooltip" title="' + Resources.SnapeHelperScriptsJS.GwRadioEmpty + '">sync_problem</i>';
        }
        return '';
    });

    var getProjectNodeLimitExceededIcon = (data, IsNodeExceeded, maxNoOfNodes) => renderIconButton(function () {
        if (IsNodeExceeded) {
            return '<i class="material-icons" title="' + SnapeApp.Helpers.Localization.parseStringTemplate(Resources.SnapeHelperScriptsJS.NodeLimitExceededError, { noOfNodes: maxNoOfNodes }) + '">error</i>';
        }
        return htmlEncode(data);
    });

    var getDeviceStateIcon = (deviceState) => renderIconButton(function () {
        if (deviceState === 0 || deviceState === 1) {
            return '<i class="material-icons runningIcon icon-with-tooltip" title="' + Resources.SnapeHelperScriptsJS.Running + '">fiber_manual_record</i>';
        } else if (deviceState === 2) {
            return '<i class="material-icons errorBlink icon-with-tooltip" title="' + Resources.SnapeHelperScriptsJS.Missing + '">warning</i>';
        }
        return '';
    });

    var getDeviceBatteryIcon = (batteryLevel, batteryValue) => renderIconButton(function () {
        if (batteryLevel === 1 && batteryValue !== null) {
            return '<i class="material-icons icon-with-tooltip" title="' + batteryValue + ' mV ">battery_full</i>';
        } else if (batteryLevel === 2 && batteryValue !== null) {
            return '<i class="material-icons icon-with-tooltip" title="' + batteryValue + ' mV ' + Resources.SnapeHelperScriptsJS.Empty + '">battery_0_bar</i>';
        } else if (batteryLevel === 3 && batteryValue !== null) {
            return '<i class="material-icons icon-with-tooltip" title="' + batteryValue + ' mV ' + Resources.SnapeHelperScriptsJS.Gone + '">battery_alert</i>';
        }
        return '';
    });

    var getDeviceRssiIcon = (rssiLevel, rssiValue) => renderIconButton(function () {
        if (rssiLevel === 1 && rssiValue !== null) {
            return '<i class="material-icons icon-with-tooltip" title="' + rssiValue + ' % ">signal_wifi_statusbar_4_bar</i>';
        } else if (rssiLevel === 2 && rssiValue !== null) {
            return '<i class="material-icons icon-with-tooltip" title="' + rssiValue + ' % ">network_wifi_3_bar</i>';
        } else if (rssiLevel === 3 && rssiValue !== null) {
            return '<i class="material-icons icon-with-tooltip" title="' + rssiValue + ' % ">network_wifi_1_bar</i>';
        } else if (rssiLevel === 4 && rssiValue !== null) {
            return '<i class="material-icons icon-with-tooltip" title="' + rssiValue + ' % ">signal_wifi_statusbar_null</i>';
        }
        return '';
    })

    var getDeviceSamplingRateIcon = (srStatus) => renderIconButton(function () {
        if (srStatus === 1) {
            return '<i class="material-icons pendingIcon icon-with-tooltip" title="' + Resources.SnapeHelperScriptsJS.Pending + '">hourglass_full</i>';
        }
        return '';
    });

    var convertRssiToRssiLevel = function (value) {
        if (value >= -80)
            return { color: "#00ff00", status: Resources.SnapeHelperScriptsJS.RssiStable };
        else if (value < -80 && value >= -90)
            return { color: "#ffff00", status: Resources.SnapeHelperScriptsJS.RssiNotGood };
        else if (value < -90 && value >= -100)
            return { color: "#ff9355", status: Resources.SnapeHelperScriptsJS.RssiBad };
        else
            return { color: "#ff0000", status: Resources.SnapeHelperScriptsJS.RssiLost };
    };

    var getDeviceUpdateStatusIcon = (deviceStatus) => renderIconButton(function () {
        if (deviceStatus === 1) {
            return '<i class="material-icons icon-with-tooltip" title="' + Resources.SnapeHelperScriptsJS.BeamBinariesUpdate + '">cloud_sync</i>';
        }

        if (deviceStatus === 2) {
            return '<i class="material-icons icon-with-tooltip" title="' + Resources.SnapeHelperScriptsJS.BeamBinariesUpgrade + '">cloud_sync</i>';
        }
        if (deviceStatus === 3) {
            return '<i class="material-icons icon-with-tooltip" title="' + Resources.SnapeHelperScriptsJS.BeamBinariesBeta + '">cloud_sync</i>';
        }
        return '';
    });

    var getRepeaterNodeIcon = (isNodeARepeater) => renderIconButton(function () {
        if (isNodeARepeater === true) {
            return '<i class="material-icons icon-with-tooltip" title=' + Resources.SnapeHelperScriptsJS.RepeaterNode + '>repeat</i>';
        }
        return '';
    });

    return {
        htmlEncode: htmlEncode,
        getNodeErrorIcon: getNodeErrorIcon,
        getDeviceRssiIcon: getDeviceRssiIcon,
        getDeviceStateIcon: getDeviceStateIcon,
        getDeviceBatteryIcon: getDeviceBatteryIcon,
        getDeviceSamplingRateIcon: getDeviceSamplingRateIcon,
        convertRssiToRssiLevel: convertRssiToRssiLevel,
        getDeviceUpdateStatusIcon: getDeviceUpdateStatusIcon,
        getfirmwareMismatchErrorIcon: getfirmwareMismatchErrorIcon,
        getProjectNodeLimitExceededIcon: getProjectNodeLimitExceededIcon,
        getRepeaterNodeIcon: getRepeaterNodeIcon
    };
});

SnapeApp.createModule("SnapeApp.Helpers.UnitTypes", function () {
    "use strict";

    var htmlEncode = function (data) {
        // "trick" to HTML encode data from JS--essentially dip it in a <div> and pull it out again
        return data || data === 0 ? $('<div/>').text(data).html() : null;
    };

    var getUnitSymbol = function (unitType, reading) {
        if (unitType === 1) {
            return reading + "°";//"<nobr>" + reading + "&deg</nobr>";
        } else if (unitType === 2) {
            return reading + " Hz";
        } else if (unitType === 3) {
            return reading + " micro";//" &#181;";
        } else if (unitType === 4) {
            return reading + " V";
        } else if (unitType === 5) {
            return reading + " mA";
        } else if (unitType === 6) {
            return reading + " °C"; //" &#8451;";
        } else if (unitType === 7) {
            return reading + " mV";
        } else if (unitType === 8) {
            return reading + " kN";
        } else if (unitType === 9) {
            return reading + " Digits";
        } else if (unitType === 10) {
            return reading + " Ω";//&#8486;
        } else if (unitType === 11) {
            return reading + " %";
        } else if (unitType === 12) {
            return reading + " mV/V";
        } else if (unitType === 13) {
            return reading + " t";
        } else if (unitType === 14) {
            return reading + " mm";
        } else if (unitType === 15) {
            return reading + " MPa";
        } else if (unitType === 16) {
            return reading + " kPa";
        } else if (unitType === 17) {
            return reading + " m";
        } else if (unitType === 18) {
            return reading + " Sinα";
        } else if (unitType === 19) {
            return reading + " %RH";
        } else if (unitType === 20) {
            return reading + " kSinα";
        } else if (unitType === 21) {
            return reading + " 20kSinα";
        } else if (unitType === 22) {
            return reading + " mm/m";
        } else if (unitType === 23) {
            return reading + " mH2O";
        } else if (unitType === 24) {
            return reading + " pulse";
        } else if (unitType === 25) {
            return reading + " pa";
        } else if (unitType === 26) {
            return reading + " dS/m";
        } else if (unitType === 27) {
            return reading + " raw";
        } else if (unitType === 28) {
            return reading + " count";
        } else if (unitType === 29) {
            return reading + " in";
        } else if (unitType === 30) {
            return reading + " mbar";
        } else if (unitType === 31) {
            return reading + " psi";
        } else if (unitType === 32) {
            return reading + " psi/°C";
        }
        return htmlEncode(reading);
    };

    return {
        htmlEncode: htmlEncode,
        getUnitSymbol: getUnitSymbol
    };
});

$(function () {
    "use strict";

    $(document).on("click", ".button_copy", function () {
        var textboxId = $(this).data('id');
        SnapeApp.Helpers.Highlight.highlightText(textboxId);
    });

    /* tooltip */
    //$(document).tooltip({
    //    hide: false,
    //    show: false
    //});
    var copy;
    $(document).on("mouseover", ".button_copy", function () {
        var inputSelector = '#' + $(this).data('id');
        copy = baseLayoutResources.clickToSelectAll;
        $(inputSelector).siblings().attr('title', copy);
    });
    $(document).on("click", ".button_copy", function () {
        var inputSelector = ".ui-tooltip-content";
        var isMac = (navigator.userAgent.toUpperCase().indexOf("MAC") !== -1);
        if (isMac) {
            copy = baseLayoutResources.commandCToCopy;
        }
        else {
            copy = baseLayoutResources.controlCToCopy;
        }
        $(inputSelector).html(copy);
    });

    //Catch any ajax call that has a 401 status and
    //take the user to the sign-in page
    $(document).ajaxError(function (e, xhr, settings) {
        if (xhr.status === 401 || xhr.status === 403) {
            window.location = '/Account/Login?isSessionTimeOut=true';
        }
    });

    SnapeApp.Helpers.Dates.localizeDates();

}, baseLayoutResources);

$(document).ready(function () {
    $('[data-toggle="tooltip"]').tooltip();
});

// this module is used frequently in SummarySetupJS in accordance with the Summary.cshtml view.
SnapeApp.createModule("SnapeApp.Helpers.channelType", function () {
    "use strict";

    // localize typeNames for the inner channels.
    var getChannelType = function (typeName) {
        typeName = typeName.toUpperCase();
        var typeValue;
        if (typeName === "NONE") {
            typeValue = "0";
        } else if (typeName === "A") {
            typeValue = "1";
        } else if (typeName === "B") {
            typeValue = "2";
        } else if (typeName === "TEMPERATURE") {
            typeValue = "3";
        } else if (typeName === "VIRTUAL") {
            typeValue = "4";
        }
        return typeValue;
    };

    return {
        getChannelType: getChannelType
    };
});

SnapeApp.createModule("SnapeApp.Helpers.DataUploadType", function () {
    "use strict";

    var getDataUploadTypeName = function (dataUploadType) {
        var typeName = "";
        if (dataUploadType === 0) {
            typeName = "LOCAL";
        } else if (dataUploadType === 1) {
            typeName = "API";
        } else if (dataUploadType === 2) {
            typeName = "FTP";
        } else if (dataUploadType === 3) {
            typeName = "ACKCIO CLOUD";
        }
        return typeName;
    };

    return {
        getDataUploadTypeName: getDataUploadTypeName
    };
});

// module used in conjunction with MapPane.js to call and load maps.
SnapeApp.createModule("SnapeApp.Helpers.Internet", function () {
    "use strict";

    function getTestUrl() {
        return new Promise((res, reject) => {
            $.ajax({
                method: "get",
                url: "/api/gateway/testInternetUrl",
                success: (data) => {
                    res(data)
                },
                error: () => {
                    reject(new Error('error'))
                }
            });
        })
    }

    function timeoutRequest(ms, promise) {
        return new Promise((resolve, reject) => {
            const timer = setTimeout(() => {
                reject(new Error('TIMEOUT'))
            }, ms)

            promise
                .then(value => {
                    clearTimeout(timer)
                    resolve(value)
                })
                .catch(reason => {
                    clearTimeout(timer)
                    reject(reason)
                })
        })
    }

    var runOnlyWithInternet = function (thenFunction, timeout = 10000, errorFunction) {
        if (navigator.onLine) {
            timeoutRequest(timeout, getTestUrl().then((url) => {
                return fetch(`https://${url}`, { // Check for internet connectivity
                    mode: 'no-cors',
                })
            })).then(() => {
                thenFunction(true);
                return true
            }).catch(() => {
                errorFunction()
            })
        } else {
            errorFunction();
        }
    };

    let retryCount = 0;
    let maxRetryAttempts = 3;
    let retryInterval = 300000; // 5 minutes interval when reachable
    let shortRetryInterval = 60000; // 1 minute interval when uncreachable
    let isGatewayReachable = false;
    let intervalId;
    var checkIfGatewayIsReachable = function () {
        $.ajax({
            type: "GET",
            url: "/api/gateway/reachability",
            async: true,
            success: function (response) {
                if (isGatewayReachable) {
                    toastr.clear();
                    clearInterval(intervalId);
                    intervalId = setInterval(checkIfGatewayIsReachable, retryInterval);
                }
            },
            error: function () {
                if (retryCount < maxRetryAttempts) {
                    retryCount++;
                    setTimeout(checkIfGatewayIsReachable, 5000); // Retry every 5 seconds when the gateway is unreachable
                    isGWPrevUnreachable = true;
                } else {
                    if (!isGatewayReachable) {
                        SnapeApp.Helpers.UIOperations.showGatewayConnection(Resources.SnapeHelperScriptsJS.GatewayConnectionLost, "toast-bottom-left");
                        clearInterval(intervalId);
                        intervalId = setInterval(checkIfGatewayIsReachable, shortRetryInterval);
                    }
                    isGatewayReachable = true;
                }
            }
        });
    };

    intervalId = setInterval(checkIfGatewayIsReachable, retryInterval);

    return {
        runOnlyWithInternet: runOnlyWithInternet,
        checkIfGatewayIsReachable: checkIfGatewayIsReachable
    };
});

SnapeApp.createModule("SnapeApp.Helpers.RegularExpression", function () {
    "use strict";

    var getRegex = function (type) {
        type = type.toLowerCase();
        if (type === "deviceid")
            return /[^a-fA-F0-9]/;
        else if (type === "devicename")
            return /[\/\\?*:|"<>`]/;
        else if (type === "sensorcode")
            return /[\/\\?*:|"<>`]/;
        else if (type === "sensorgroup")
            return /[\/\\?*:|"<>`]/;
        else if (type === "apiurl" || type === "url")
            return /^(((?:25[0-5]|2[0-4][0-9]|1[0-9][0-9]|[0-9][0-9]|[0-9])\.(?:(?:25[0-5]|2[0-4][0-9]|1[0-9][0-9]|[0-9][0-9]|[0-9])\.)(?:(?:25[0-5]|2[0-4][0-9]|1[0-9][0-9]|[0-9][0-9]|[0-9])\.)(?:(?:25[0-5]|2[0-4][0-9]|1[0-9][0-9]|[0-9][0-9]|[0-9]))|(?:(?:(?:\w+\.){1,2}[\w]{2,3})))(?::(\d+))?((?:\/[\w]+)*)(?:\/|(\/[\w]+\.[\w]{3,4})|(\?(?:([\w]+=[\w]+)&)*([\w]+=[\w]+))?|\?(?:(wsdl|wadl))))$/;
        else if (type === "aeskey")
            return /[^a-fA-F0-9]/;
        else if (type === "ip")
            return /^((25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/;
        else if (type === "pin")
            return /^[0-9]+$/;
    };

    return {
        getRegex: getRegex
    };
});

SnapeApp.createModule("SnapeApp.Helpers.UIOperations", function () {
    "use strict";
    var timeout = 5000;
    var hideModal = function (modal, timeout) {
        if (timeout !== undefined || timeout !== null)
            timeout = 3000;
        setTimeout(function () { modal.modal('hide'); }, timeout);
    };

    var showMessage = function (message, isSuccess, location, target) {
        var opts = {
            "closeButton": true,
            "positionClass": location,
            "onclick": null,
            "showDuration": "300",
            "hideDuration": "1000",
            "timeOut": timeout.toString(),
            "extendedTimeOut": "1000",
            "showEasing": "swing",
            "hideEasing": "linear",
            "showMethod": "fadeIn",
            "hideMethod": "fadeOut",
            target
        };

        if (isSuccess)
            toastr.success(message, "", opts);
        else
            toastr.error(message, "", opts);
    };

    var showPersistentMessage = function (message, isSuccess, location) {
        var opts = {
            "closeButton": true,
            "positionClass": location,
            "onclick": null,
            "showDuration": "300",
            "hideDuration": "1000",
            "timeOut": "0", //message will not dissapear unless user click/hover the message
            "extendedTimeOut": "1000",
            "showEasing": "swing",
            "hideEasing": "linear",
            "showMethod": "fadeIn",
            "hideMethod": "fadeOut"
        };

        if (isSuccess)
            toastr.success(message, "", opts);
        else
            toastr.error(message, "", opts);
    };

    var showInfo = function (message, location) {
        let showInfoOpts = {
            "closeButton": true,
            "debug": false,
            "positionClass": location,
            "onclick": null,
            "showDuration": "300",
            "hideDuration": "1000",
            "timeOut": "0",
            "extendedTimeOut": "0",
            "showEasing": "swing",
            "hideEasing": "linear",
            "showMethod": "fadeIn",
            "hideMethod": "fadeOut"
        };

        toastr.info(message, "", showInfoOpts);
    };

    var showGatewayConnection = function (message, location) {
        var opts = {
            "closeButton": false,
            "positionClass": location,
            "onclick": null,
            "showDuration": "300",
            "hideDuration": "1000",
            "timeOut": "0",
            "extendedTimeOut": "1000",
            "showEasing": "swing",
            "hideEasing": "linear",
            "showMethod": "fadeIn",
            "hideMethod": "fadeOut"
        };
        toastr.warning(message, "", opts);
    };

    return {
        hideModal: hideModal,
        showMessage: showMessage,
        showPersistentMessage: showPersistentMessage,
        showGatewayConnection: showGatewayConnection,
        showInfo: showInfo,
        timeout: timeout
    };
});

SnapeApp.createModule("SnapeApp.Helpers.BrowserUtil", function () {
    "use strict";

    function isSafari() {
        return /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
    }

    return {
        isSafari
    };
});

// setupFlags() and setCurrentCulture methods that will show country flags and configure the dropdown to always show the current culture first
SnapeApp.createModule("SnapeApp.Helpers.Localization", function () {
    "use strict";

    // function to set up the option tags for use with msdropdown plugin. 
    var setupFlags = function (dropdown) {
        var countryValue;
        var culture;
        var selector = document.getElementById(dropdown);
        var x = "";


        // iterate through the dropdown and add a country flag based on the country code value for each option in the culture-dropdown selection element...
        $("#" + dropdown + " option").each(function () {
            countryValue = $(this).val();  // grab the dropdown value, which will be used to find the matching flag.
            culture = $(this).text(); // grab the dropdown text
            // rewrite the current option element to include the blank gif, adorned with the flag (css class) that matches the option tag's countryValue.
            // Keep the original inner text of the culture.
            //x += "<option value='" + countryValue + "' data-image='lib/msdropdown/img/blank.gif' data-imagecss='flag " + countryValue + "' data-title='" + culture + "'>" + culture + "</option>";
            x += "<option value='" + countryValue + "'>" + culture + "</option>";

        });
        // when the loop finishes, set the innerHTML of the dropdown to the new option tags.
        selector.innerHTML = x;
    };

    // function to set the msdropdown selection element to the current culture. Uses the same AJAX method used to get the culture for google maps, 
    // and a numeric culture - number dictionary
    var setCurrentCulture = function (dropdown, isTrimmedLabel) {
        // dictionary with numeric values corresponding to a culture.
        // One of these will be used on every page load to ensure the selectedIndex value of msdropdown points at the current culture of the page.
        var culturedictionary = {
            'en-AU': 0,
            'es-ES': 1,
            'pt-PT': 2,
            'ja-JP': 3,
            'zh-Hans': 4
            //'ko-KR': 1, //disable korean until translation is verified
        };
        // we need to grab the current culture so we can determine what option to show as selected in the msdropdown so  we use our handy-dandy ajax method...
        $.ajax({
            type: "GET", // we are getting the culture of the page.
            url: '/api/project/culture', // routes to GetCulture() in ProjectApiController.cs
            contentType: "application/json; charset=utf-8",
            datatype: "json", // return json.
            // on success...
            success: function (data) {
                if (isTrimmedLabel === true) {
                    dropdown.val(data);
                    window.snapeCultureInfo = data;
                } else {
                    var countryOption = culturedictionary[data]; // get the numeric value corresponding to our current culture , en-US = 0,
                    dropdown.set("selectedIndex", countryOption); // plugin method to set the dropdown to the numeric value (matching the index of an option tag in the dropdown) returned from the culturedictionary.

                    $("#" + dropdown.id + "_msdd").css("opacity", 1);
                    $("#" + dropdown.id + "_titleText").remove();
                }
            }
        });
    };

    function getCulture() {
        var properCulture;
        // quick ajax call to grab the language.
        $.ajax({
            type: "GET", // we are getting the culture of the page.
            async: false, // async being set to false is necessary to run the AJAX call immediately, otherwise, the dataTable will be constructed before properCulture is set and it won't change the language of the UI.
            url: '/api/project/culture', // url routes to GetCulture() in ProjectApiController.cs
            datatype: "json", // return json.
            // on success...
            success: function (data) {
                // determine culture from culture code.
                // add to this as more cultures are added.
                if (data === "en-AU") {
                    properCulture = "English"; // make properCulture global so it actually  saves the change for use in the dataTable below.
                }
                else if (data === "ko-KR") {
                    properCulture = "Korean";
                }
                else if (data == "es-ES") {
                    properCulture = "Spanish";
                }
                else if (data == "pt-PT") {
                    properCulture = "Portuguese";
                } else if (data == "ja-JP") {
                    properCulture = "Japanese";
                } else if (data == "zh-Hans") {
                    properCulture = "Chinese";
                }
            },
            error: function (data) {
                properCulture = "English"; // default to English
            }
        });
        return properCulture;
    }

    function parseStringTemplate(str, obj) {
        let parts = str.split(/\$\{(?!\d)[\wæøåÆØÅ]*\}/);
        let args = str.match(/[^{\}]+(?=})/g) || [];
        let parameters = args.map(argument => obj[argument] || (obj[argument] === undefined ? "" : obj[argument]));
        return String.raw({ raw: parts }, ...parameters);
    }

    return {
        setupFlags: setupFlags,
        getCulture: getCulture,
        setCurrentCulture: setCurrentCulture,
        parseStringTemplate: parseStringTemplate
    };
});

SnapeApp.createModule("SnapeApp.Helpers.Mapbox", function () {
    "use strict";
    const style = {
        "version": 8,
        "sources": {
            "countries": {
                "type": "vector",
                "tiles": [location.origin + "/lib/mapbox/countries/{z}/{x}/{y}.pbf"],
                "maxzoom": 6
            }
        },
        "glyphs": location.origin + "/lib/mapbox/font/{fontstack}/{range}.pbf",
        "layers": [
            {
                "id": "background",
                "type": "background",
                "paint": {
                    "background-color": "#ddeeff"
                }
            }, {
                "id": "country-glow-outer",
                "type": "line",
                "source": "countries",
                "source-layer": "country",
                "layout": {
                    "line-join": "round"
                },
                "paint": {
                    "line-color": "#226688",
                    "line-width": 5,
                    "line-opacity": {
                        "stops": [[0, 0], [1, 0.1]]
                    }
                }
            }, {
                "id": "country-glow-inner",
                "type": "line",
                "source": "countries",
                "source-layer": "country",
                "layout": {
                    "line-join": "round"
                },
                "paint": {
                    "line-color": "#226688",
                    "line-width": {
                        "stops": [[0, 1.2], [1, 1.6], [2, 2], [3, 2.4]]
                    },
                    "line-opacity": 0.8
                }
                // rainbow start
            }, {
                "id": "area-white",
                "type": "fill",
                "source": "countries",
                "filter": ["in", "ADM0_A3", 'ATA', 'GRD', 'GRL'],
                "source-layer": "country",
                "paint": {
                    "fill-color": "#f5f5f5"
                }
            }, {
                "id": "area-red",
                "type": "fill",
                "source": "countries",
                "filter": ["in", "ADM0_A3", 'AFG', 'ALD', 'BEN', 'BLR', 'BWA', 'COK', 'COL', 'DNK', 'DOM', 'ERI', 'FIN', 'FRA', 'FRO', 'GIB', 'GNB', 'GNQ', 'GRC', 'GTM', 'JPN', 'KIR', 'LKA', 'MHL', 'MMR', 'MWI', 'NCL', 'OMN', 'RWA', 'SMR', 'SVK', 'SYR', 'TCD', 'TON', 'URY', 'WLF',
                    'AZE', 'BGD', 'CHL', 'CMR', 'CSI', 'DEU', 'DJI', 'GUY', 'HUN', 'IOA', 'JAM', 'LBN', 'LBY', 'LSO', 'MDG', 'MKD', 'MNG', 'MRT', 'NIU', 'NZL', 'PCN', 'PYF', 'SAU', 'SHN', 'STP', 'TTO', 'UGA', 'UZB', 'ZMB',
                    'AGO', 'ASM', 'ATF', 'BDI', 'BFA', 'BGR', 'BLZ', 'BRA', 'CHN', 'CRI', 'ESP', 'HKG', 'HRV', 'IDN', 'IRN', 'ISR', 'KNA', 'LBR', 'LCA', 'MAC', 'MUS', 'NOR', 'PLW', 'POL', 'PRI', 'SDN', 'TUN', 'UMI', 'USA', 'USG', 'VIR', 'VUT',
                    'ARE', 'ARG', 'BHS', 'CIV', 'CLP', 'DMA', 'ETH', 'GAB', 'HMD', 'IND', 'IOT', 'IRL', 'IRQ', 'ITA', 'KOS', 'LUX', 'MEX', 'NAM', 'NER', 'PHL', 'PRT', 'RUS', 'SEN', 'SUR', 'TZA', 'VAT',
                    'AUT', 'BEL', 'BHR', 'BMU', 'BRB', 'CYN', 'DZA', 'EST', 'FLK', 'GMB', 'GUM', 'HND', 'JEY', 'KGZ', 'LIE', 'MAF', 'MDA', 'NGA', 'NRU', 'SLB', 'SOL', 'SRB', 'SWZ', 'THA', 'TUR', 'VEN', 'VGB',
                    'AIA', 'BIH', 'BLM', 'BRN', 'CAF', 'CHE', 'COM', 'CPV', 'CUB', 'ECU', 'ESB', 'FSM', 'GAZ', 'GBR', 'GEO', 'KEN', 'LTU', 'MAR', 'MCO', 'MDV', 'NFK', 'NPL', 'PNG', 'PRY', 'QAT', 'SLE', 'SPM', 'SYC', 'TCA', 'TKM', 'TLS', 'VNM', 'WEB', 'WSB', 'YEM', 'ZWE',
                    'ABW', 'ALB', 'AND', 'ATC', 'BOL', 'COD', 'CUW', 'CYM', 'CYP', 'EGY', 'FJI', 'GGY', 'IMN', 'KAB', 'KAZ', 'KWT', 'LAO', 'MLI', 'MNP', 'MSR', 'MYS', 'NIC', 'NLD', 'PAK', 'PAN', 'PRK', 'ROU', 'SGS', 'SVN', 'SWE', 'TGO', 'TWN', 'VCT', 'ZAF',
                    'ARM', 'ATG', 'AUS', 'BTN', 'CAN', 'COG', 'CZE', 'GHA', 'GIN', 'HTI', 'ISL', 'JOR', 'KHM', 'KOR', 'LVA', 'MLT', 'MNE', 'MOZ', 'PER', 'SAH', 'SGP', 'SLV', 'SOM', 'TJK', 'TUV', 'UKR', 'WSM'
                ],
                "source-layer": "country",
                "paint": {
                    "fill-color": "#e4eccf"
                }
            }, {
                "id": "geo-lines",
                "type": "line",
                "source": "countries",
                "source-layer": "geo-lines",
                "paint": {
                    "line-color": "#226688",
                    "line-width": {
                        "stops": [[0, 0.2], [4, 1]]
                    },
                    "line-dasharray": [6, 2]
                }
            }, {
                "id": "land-border-country",
                "type": "line",
                "source": "countries",
                "source-layer": "land-border-country",
                "paint": {
                    "line-color": "#fff",
                    "line-width": {
                        "base": 1.5,
                        "stops": [[0, 0], [1, 0.8], [2, 1]]
                    }
                }
            }, {
                "id": "state",
                "type": "line",
                "source": "countries",
                "source-layer": "state",
                "minzoom": 3,
                "filter": ["in", "ADM0_A3", 'USA', 'CAN', 'AUS'],
                "paint": {
                    "line-color": "#226688",
                    "line-opacity": 0.25,
                    "line-dasharray": [6, 2, 2, 2],
                    "line-width": 1.2
                }
                // LABELS
            }, {
                "id": "country-abbrev",
                "type": "symbol",
                "source": "countries",
                "source-layer": "country-name",
                "minzoom": 1.8,
                "maxzoom": 3,
                "layout": {
                    "text-field": "{ABBREV}",
                    "text-font": ["Open Sans Semibold"],
                    "text-transform": "uppercase",
                    "text-max-width": 20,
                    "text-size": {
                        "stops": [[3, 10], [4, 11], [5, 12], [6, 16]]
                    },
                    "text-letter-spacing": {
                        "stops": [[4, 0], [5, 1], [6, 2]]
                    },
                    "text-line-height": {
                        "stops": [[5, 1.2], [6, 2]]
                    }
                },
                "paint": {
                    "text-halo-color": "#fff",
                    "text-halo-width": 1.5
                }
            }, {
                "id": "country-name",
                "type": "symbol",
                "source": "countries",
                "source-layer": "country-name",
                "minzoom": 3,
                "layout": {
                    "text-field": "{NAME}",
                    "text-font": ["Open Sans Semibold"],
                    "text-transform": "uppercase",
                    "text-max-width": 20,
                    "text-size": {
                        "stops": [[3, 10], [4, 11], [5, 12], [6, 16]]
                    }
                },
                "paint": {
                    "text-halo-color": "#fff",
                    "text-halo-width": 1.5
                }
            }, {
                "id": "geo-lines-lables",
                "type": "symbol",
                "source": "countries",
                "source-layer": "geo-lines",
                "minzoom": 1,
                "layout": {
                    "text-field": "{DISPLAY}",
                    "text-font": ["Open Sans Semibold"],
                    "text-offset": [0, 1],
                    "symbol-placement": "line",
                    "symbol-spacing": 600,
                    "text-size": 9
                },
                "paint": {
                    "text-color": "#226688",
                    "text-halo-width": 1.5
                }
            }
        ]
    };
    const defaultLngLat = [103.8198, 1.3521];
    function toggleLocationInputButton() {
        ($('#searchByLatLong').is(':checked')) ? $("#appendLocationButton").show() : $("#appendLocationButton").hide();
    }

    const getApiMapboxGeocodingUrl = (location, token) => {
        return "https://api.mapbox.com/geocoding/v5/mapbox.places/" + location + ".json?access_token=" + token
    }

    return {
        OfflineStyle: style,
        toggleLocationInputButton: toggleLocationInputButton,
        defaultLngLat: defaultLngLat,
        getApiMapboxGeocodingUrl
    };
});

SnapeApp.createModule("SnapeApp.Helpers.Highchart", function () {

    // list of available date format
    const dateTimeFormat = [{
        format: 'YYYY/MM/DD HH:mm:ss',
        highChartFormat: '%Y/%m/%d'
    },
    {
        format: 'DD/MM/YYYY HH:mm:ss',
        highChartFormat: '%d/%m/%Y'
    },
    {
        format: 'YYYY-MM-DD HH:mm:ss',
        highChartFormat: '%Y-%m-%d'
    }]

    // helper to convert ackcio date format to highchart date format
    const getDateTimeFormat = (localDateTimeFormatValue) => {
        const format = dateTimeFormat.find((dateTime) => dateTime.format === localDateTimeFormatValue)
        return format ? format.highChartFormat : dateTimeFormat[0].highChartFormat
    }

    return {
        getDateTimeFormat
    };
});

SnapeApp.createModule("SnapeApp.Helpers.Input", function () {
    function onlyAllowNumberAndDot(evt) {
        var theEvent = evt || window.event;
        var key = theEvent.keyCode || theEvent.which;
        key = String.fromCharCode(key);

        // Allow digits, decimal point, exponent, plus/minus sign
        // Allow backspace (key code 8), tab (key code 9), and arrow keys (key codes 37, 38, 39, 40)
        // Allow Ctrl+C (copy), Ctrl+V (paste), and Ctrl+A (select all)

        var keyCode = theEvent.keyCode;
        var ctrlKey = theEvent.ctrlKey;
        var shiftKey = theEvent.shiftKey;
    
        // Allow digits, decimal point, exponent, plus/minus sign
        var isValidKey = /^[\d.eE+-]$/.test(evt.key);
        
        // Allow backspace (key code 8), tab (key code 9), and arrow keys (key codes 37, 38, 39, 40)
        var isControlKey = keyCode === 8 || keyCode === 9 || (keyCode >= 37 && keyCode <= 40);
    
        // Allow Ctrl+C (copy), Ctrl+V (paste), and Ctrl+A (select all)
        var isCtrlCombination = ctrlKey && (keyCode === 67 || keyCode === 86 || keyCode === 65);
    
        // Allow Shift and + (plus sign)
        var isShiftPlus = shiftKey && keyCode === 187;

        // current value
        var value = evt.target.value;
 
        let inputEl = evt.target;

        let start = inputEl.selectionStart;
        let end = inputEl.selectionEnd;
        
        let currentValue = inputEl.value;

        if (!(isCtrlCombination || isControlKey) || (shiftKey && key === '+')) {
            // update to updated value based on latest user input
            value = currentValue.slice(0, start) + evt.key + currentValue.slice(end);
        }

        // Get the value of the input field
        var input = value.replace(/^\s+|\s+$/gm, '')

        // Regular expression to allow only numbers, a single dot, 'e' or 'E', '+' and minus sign
        // valid: +2.22, -2.22, 2.22e-10, -2.22e+10
        var regex = /^[+-]?$|^[+-]?(\d+(\.\d*)?|\.\d+)([eE][+-]?\d*)?$/;

        // check if keyCode is valid
        var validKey = (isValidKey || isControlKey || isCtrlCombination || isShiftPlus);

        if (!validKey) {
            theEvent.returnValue = false;
            if (theEvent.preventDefault) theEvent.preventDefault();
        }

        if ((validKey && input && !input.match(regex))) {
            theEvent.returnValue = false;
            if (theEvent.preventDefault) theEvent.preventDefault();
        }

    }

    function onlyPasteValidNumber(e) {
        // Prevent the default paste action
        e.preventDefault();

        // Get the pasted data
        let pastedData = (e.clipboardData || window.clipboardData).getData('text');

        // Remove non-digit characters
        let digitsOnly = parseFloat(pastedData.replace(',', '.'));

        // Insert the cleaned data into the input field
        document.execCommand('insertText', false, digitsOnly);
    }

    return {
        onlyAllowNumberAndDot,
        onlyPasteValidNumber
    };
});

